import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { SwiperSlide } from 'swiper/react';
import qs from 'query-string';

// Components
import Slider from '../../../components/Slider';

// State
import { applyStock } from '../../../state/modules/stock/actions';

// Router
import { getRoute } from '../../../entry/routes';

// Utils
import { customScrollTo } from '../../../utils/customScrollTo';

// Styles
import styles from './MainSlider.styl';

const cx = classNames.bind(styles);

export default function MainSlider(props) {
  const { className, topRef, view } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const stockCode = useSelector(state => state.stock.data.code);
  const list = useSelector(state => state.banner.list);

  const { bannerId, category } = qs.parse(location.search, { parseNumbers: true });
  const bannersList = list.filter(banner => banner.type === 'slider');
  const bannerInitialIndex = bannersList.findIndex(banner => banner.id === bannerId);

  const onBannerClick = banner => {
    // переходим по линку после нажатия
    if (banner.link) {
      const urlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

      if (urlRegex.test(banner.link.trim())) {
        Object.assign(document.createElement('a'), { href: banner.link, target: '_blank' }).click();
      } else {
        history.push(getRoute(banner.link));
      }
      // применяем промокод после нажатия
    } else if (banner.stock && stockCode !== banner.stock) {
      dispatch(applyStock({ stock_code: banner.stock }));

      if (topRef?.current) {
        topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  useEffect(() => {
    if (category) {
      const currentElement = document.getElementById(category);

      if (currentElement) {
        customScrollTo(currentElement, { block: 'start' });
      }

      history.replace({ search: qs.exclude(location.search, ['category']) });
    }
  }, [category]);

  const swiperParams = {
    slidesPerView: view === 'desktop' ? 1.75 : 1,
    spaceBetween: 20,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    navigation: true,
    initialSlide: bannerInitialIndex === -1 ? 0 : bannerInitialIndex,
    preloadImages: false,
    lazy: { loadPrevNext: true, loadOnTransitionStart: true },
    watchSlidesVisibility: true,
  };

  return (
    <div className={cx('MainSlider', `MainSlider_${view}`, className)}>
      <div className={cx('MainSlider__header')}></div>
      {list.length > 0 ? (
        <Slider swiperParams={swiperParams} isOutsidePagination>
          {bannersList.map(banner => (
            <SwiperSlide
              key={banner.image_full}
              className={cx('MainSlider__item')}
              onClick={() => onBannerClick(banner)}
              tag="button"
            >
              {({ isActive }) => (
                <picture>
                  <source
                    data-srcset={
                      view === 'phone' ? banner.image_mobile_webp : banner.image_full_webp
                    }
                    type="image/webp"
                  />
                  <img
                    className={`swiper-lazy ${cx('MainSlider__item-img', {
                      'MainSlider__item-img_active': isActive,
                    })}`}
                    data-srcset={view === 'phone' ? banner.image_mobile : banner.image_full}
                    alt="banner"
                  />
                </picture>
              )}
            </SwiperSlide>
          ))}
        </Slider>
      ) : (
        <div className={cx('MainSlider__preloader')}>
          <div className={cx('MainSlider__spinner')} />
        </div>
      )}
    </div>
  );
}

MainSlider.defaultProps = {
  className: '',
  topRef: undefined,
};

MainSlider.propTypes = {
  className: PropTypes.string,
  topRef: PropTypes.object,
  view: PropTypes.string.isRequired,
};
