import React, { useRef, useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay, Lazy } from 'swiper';
import { Swiper } from 'swiper/react';
import classNames from 'classnames';

// Styles
import './Slider.styl';

SwiperCore.use([Navigation, Pagination, A11y, Autoplay, Lazy]);

export default function Slider(props) {
  const {
    className,
    children,
    swiperParams,
    lazyLoad,
    isOutsideNavigation,
    isOutsidePagination,
    newest,
  } = props;

  const [isDisableSwiperButtons, setIsDisableSwiperButtons] = useState(false);

  const swiperRef = useRef(null);
  // false - нет левой, true - нет правой, null - обе стрелки, undefined - вообще убрать стрелки
  const [isEnd, setIsEnd] = useState(false);
  const paginationContainerRef = useRef(null);

  // если в конце прокрутки сработал лейзи лоадинг и подтянулись новые элементы
  // то сделать обе стрелки доступными
  useEffect(() => {
    setIsEnd(prev => (prev === true ? null : prev));
  }, [Children.count(children)]);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current && paginationContainerRef.current) {
      const sliderBlockWidth = sliderRef.current?.offsetWidth;
      setIsDisableSwiperButtons(sliderBlockWidth > children.length * 346);
      if (sliderBlockWidth > children.length * 346 && paginationContainerRef.current) {
        if (paginationContainerRef.current.childNodes[1]) {
          paginationContainerRef.current.childNodes[0].style.display = 'none';
        }
      } else if (paginationContainerRef.current.childNodes[1]) {
        paginationContainerRef.current.childNodes[0].style.display = 'block';
      }
    }
  }, [sliderRef.current, children, paginationContainerRef.current]);

  const goNext = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slidePrev();
    }
  };

  const onSwiper = swiper => {
    swiperRef.current = swiper;

    if (swiper.isBeginning && swiper.isEnd) {
      setIsEnd(undefined);
    }
  };

  const onReachBeginning = () => {
    setIsEnd(false);
  };

  const onFromEdge = () => {
    setIsEnd(null);
  };

  const onReachEnd = () => {
    setIsEnd(true);
    // подтянуть новые элементы в конце прокрутки
    lazyLoad?.();
  };

  if (isOutsidePagination) {
    swiperParams.pagination = {
      ...(swiperParams.pagination || {}),
      el: '.swiper-pagination-outside',
    };
  }

  return (
    <div className={classNames('swiper', className, { swiper_newest: newest })} ref={sliderRef}>
      <Swiper
        {...swiperParams}
        onSwiper={onSwiper}
        onReachEnd={onReachEnd}
        onFromEdge={onFromEdge}
        onReachBeginning={onReachBeginning}
      >
        {children}
      </Swiper>
      {isOutsidePagination && (
        <div className="swiper-pagination swiper-pagination-outside" ref={paginationContainerRef} />
      )}
      {isOutsideNavigation && (
        <button
          className={classNames('swiper-button-prev', 'swiper-button-prev-outside', {
            'swiper-button-prev-outside_disabled':
              isEnd === undefined || isEnd === false || isDisableSwiperButtons,
          })}
          onClick={goPrev}
          type="button"
        />
      )}
      {isOutsideNavigation && (
        <button
          className={classNames('swiper-button-next', 'swiper-button-next-outside', {
            'swiper-button-next-outside_disabled':
              isEnd === undefined || isEnd === true || isDisableSwiperButtons,
          })}
          onClick={goNext}
          type="button"
        />
      )}
    </div>
  );
}

Slider.defaultProps = {
  className: '',
  swiperParams: {},
  lazyLoad: () => null,
  isOutsideNavigation: false,
  isOutsidePagination: false,
  newest: false,
};

Slider.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  swiperParams: PropTypes.object,
  lazyLoad: PropTypes.func,
  isOutsideNavigation: PropTypes.bool,
  isOutsidePagination: PropTypes.bool,
  newest: PropTypes.bool,
};
